import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useRole } from "routes/ProtectedRoutes";
import {
  IS_ADMIN,
  IS_MANAGER_BRAND,
  listRole,
  listForAdmin,
  listForBrandManager,
  listForShopManager,
} from "@sharecode/common/redux/actions/actionTypes";
import { mapRole } from "@sharecode/common/redux/actions/actionTypes";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { DefaultServiceHandler } from "utils/http-common";
import { useSearchParams } from "react-router-dom";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSelect from "components/SuiSelect";
import SuiButton from "components/SuiButton";
// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenUserDrawer } from "context";
import { getUser, postUser, updateUser } from "@sharecode/common/redux/actions/user";
// Custom styles for the Configurator
import UserCreateUpdateRoot from "./userCreateUpdateRoot";
import { useBrandData } from "layouts/pages/brandList/data/brandData";
import useShopData from "layouts/pages/shopList/data/dataTableData";
// userCreateUpdate components
import FormField from "./FormField";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { IS_MANAGER_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { IS_EMPLOYEE_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { IS_EMPLOYEE_BRAND } from "@sharecode/common/redux/actions/actionTypes";
import { REMOVE_EDITED_USER } from "@sharecode/common/redux/actions/actionTypes";
import { IS_ADMIN_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { getUserBrand } from "@sharecode/common/services/constantApi";
import { IS_EMPLOYEE_BRAND_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { IS_EMPLOYEE_SHOP_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_BRAND_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_SHOP_VALUE } from "@sharecode/common/redux/actions/actionTypes";
import { BrandService } from "@sharecode/common/services";

function userCreateUpdate(props) {
  const [controller, dispatch] = useSoftUIController();
  const [availableBrands, setAvailableBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState();
  const [availableShops, setAvailableShops] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [availableRoles, setAvailableRoles] = useState();

  const { openUserDrawer } = controller;
  const role = useRole();
  const disp = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchBrand, setSearchBrand] = useState();
  const [filteredBrand, setFilteredBrand] = useState();

  const authBrand = getUserBrand();

  const disabledSelect = {
    Menu: () => null,
    MenuList: () => null,
    DropdownIndicator: () => null,
  };

  if (role === IS_ADMIN_VALUE) {
    const brands = useBrandData(1, 500, "");
    useEffect(() => {
      const tempBrand = [];
      if (props.brand.results && props.brand.results.length > 0) {
        props.brand.results.map((item) => {
          // if (item.is_active)
          tempBrand.push({ value: item.id, label: item.name });
        });
        setAvailableBrands(tempBrand);
      }
    }, [props.brand.results]);

    useEffect(async () => {
      if (!searchBrand || searchBrand.length == 0) {
        setFilteredBrand(null);
        return;
      }

      const fetched = await BrandService.getBrand(1, 50, searchBrand);
      const filtered = fetched.results.map((item) => {
        return { value: item.id, label: item.name };
      });
      setFilteredBrand(filtered);
    }, [searchBrand]);
  }

  if (role === IS_ADMIN_VALUE || role === IS_MANAGER_BRAND || role === IS_MANAGER_SHOP) {
    const shops = useShopData(1, 50, "");
    useEffect(() => {
      const tempShop = [];
      if (props.shop.results && props.shop.results.length > 0) {
        props.shop.results.map((item) => {
          // if (item.is_active)
          tempShop.push({ value: item.id, label: item.name });
        });
        setAvailableShops(tempShop);
      }
    }, [props.shop.results]);
  }

  useEffect(() => {
    if (role === IS_ADMIN) {
      setAvailableRoles(listForAdmin);
    } else if (role === IS_MANAGER_BRAND) {
      setAvailableRoles(listForBrandManager);
    } else if (role === IS_MANAGER_SHOP) {
      setAvailableRoles(listForShopManager);
    }

    setSelectedRole(
      props.userToEdit.role
        ? { value: props.userToEdit.role, label: mapRole[props.userToEdit.role] }
        : ""
    );
  }, []);

  useEffect(() => {
    setSelectedRole(
      props.userToEdit.role
        ? { value: props.userToEdit.role, label: mapRole[props.userToEdit.role] }
        : ""
    );
  }, [props.userToEdit.role]);

  const userAddSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(1, "Votre prénom est trop court")
      .max(50, "Votre prénom est trop long")
      .required("prénom requis"),
    last_name: Yup.string()
      .min(1, "Votre nom est trop court")
      .max(50, "Votre nom est trop long")
      .required("Nom requis"),
    email: Yup.string().email("Email invalide").max(60, "Email trop long").required("Email requis"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: props.userToEdit.email ? props.userToEdit.email : "",
      first_name: props.userToEdit.first_name ? props.userToEdit.first_name : "",
      last_name: props.userToEdit.last_name ? props.userToEdit.last_name : "",
      role: props.userToEdit.role
        ? { value: props.userToEdit.role, label: props.userToEdit.role }
        : { value: "", label: "" },
      shops: props.userToEdit.shops ? props.userToEdit.shops : "",
      brand: props.userToEdit.brand ? props.userToEdit.brand : "",
    },
    validationSchema: userAddSchema,
    onSubmit: () => {
      if (props.userToEdit.id) {
        modifyUser(formik);
      } else {
        addUser(formik);
      }
    },
  });

  const handleCloseUserDrawer = () => {
    disp({
      type: REMOVE_EDITED_USER,
      payload: { deleted: {} },
    });
    setOpenUserDrawer(dispatch, false);
    formik.resetForm();
  };
  const addUser = (form) => {
    let shopArr = [];
    selectedShops.map(function (item) {
      shopArr.push(item.value);
    });
    const data = {
      form: form,
      selectedRole: selectedRole.value,
      selectedShops: shopArr,
      selectedBrand: selectedBrand ? selectedBrand : authBrand,
    };
    disp(postUser(data))
      .then((response) => {
        disp(
          getUser(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        ).then((response) => {
          handleCloseUserDrawer();
          disp(
            DefaultServiceHandler(
              "success",
              "success",
              `Le ${selectedRole.label} a été ajouté avec succès!`
            )
          );
        });
      })
      .catch((error) => {
        let message = "";
        if (error.response.data.brand !== undefined) {
          message = error.response.data.brand;
        } else if (error.response.data.first_name !== undefined) {
          message = error.response.data.first_name;
        } else if (error.response.data.last_name !== undefined) {
          message = error.response.data.last_name;
        } else if (error.response.data.email !== undefined) {
          message = error.response.data.email;
        } else if (error.response.data.role !== undefined) {
          message = error.response.data.role;
        }
        disp(DefaultServiceHandler("error", "error", message));
      });
  };

  const modifyUser = (form) => {
    let shopArr = [];
    selectedShops.map(function (item) {
      shopArr.push(item.value);
    });
    const data = {
      id: props.userToEdit.id ?? props.userToEdit.id,
      selectedRole: selectedRole.value,
      selectedBrand: selectedBrand
        ? selectedBrand
        : props.userToEdit.brand && props.userToEdit.brand.id
        ? props.userToEdit.brand.id
        : null,
      selectedShops: shopArr,
    };
    disp(updateUser(data))
      .then((response) => {
        disp(
          getUser(searchParams.get("page"), searchParams.get("size"), searchParams.get("search"))
        ).then((response) => {
          handleCloseUserDrawer();
          disp(DefaultServiceHandler("success", "success", "Utilisateur modifié avec succès!"));
        });
      })
      .catch((error) => {
        console.log(error);
        disp(
          DefaultServiceHandler(
            "error",
            "error",
            "Erreur lors de la modification de l'utilisateur."
          )
        );
      });
  };

  const handleSelectRole = (event) => {
    setSelectedRole({ value: event.value, label: event.label });
  };

  const handleChangeShops = (event, value) => {
    setSelectedShops(value);
  };

  const handleChangeBrand = (event, value) => {
    setSelectedBrand(value.value);
  };

  return (
    <UserCreateUpdateRoot variant="permanent" ownerState={{ openUserDrawer }}>
      <form onSubmit={formik.handleSubmit}>
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={3}
          pb={0}
          px={3}
          pl={0}
        >
          <SuiBox>
            <SuiTypography variant="h4" color="primary">
              {!props.userToEdit || Object.keys(props.userToEdit).length === 0
                ? "Ajouter un Utilisateur"
                : "Modifier un Utilisateur"}
            </SuiTypography>
          </SuiBox>

          <Icon
            sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
              fontSize: `${size.md} !important`,
              fontWeight: `${fontWeightBold} !important`,
              stroke: dark.main,
              strokeWidth: "2px",
              cursor: "pointer",
              mt: 2,
            })}
            onClick={handleCloseUserDrawer}
          >
            close
          </Icon>
        </SuiBox>
        <Divider />
        <SuiBox mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="first_name"
                name="first_name"
                label="Prénom"
                error={formik.touched.first_name && formik.errors.first_name != null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.first_name}
                placeholder="Jean"
                disabled={props.userToEdit.role ? true : false}
                data-cy="inputFirstNameUserCreate"
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.first_name && formik.errors.first_name ? (
                    formik.errors.first_name
                  ) : (
                    <></>
                  )}
                </SuiTypography>
              </SuiBox>
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                id="last_name"
                name="last_name"
                label="Nom"
                error={formik.touched.last_name && formik.errors.last_name != null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                placeholder="Dupont"
                disabled={props.userToEdit.role ? true : false}
                data-cy="inputLastNameUserCreate"
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.last_name && formik.errors.last_name ? (
                    formik.errors.last_name
                  ) : (
                    <></>
                  )}
                </SuiTypography>
              </SuiBox>
            </Grid>
            {/* <Grid item xs={12}>
                  <FormField type="text" id="username" name="username" label="Username" 
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  placeholder="" disabled={props.userToEdit.role ? true : false} />
                </Grid> */}
            <Grid item xs={12}>
              <FormField
                type="text"
                id="email"
                name="email"
                label="Email"
                error={formik.touched.email && formik.errors.email != null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                placeholder="jean.dupont@gmail.com"
                disabled={props.userToEdit.role ? true : false}
                data-cy="inputEmailUserCreate"                
              />
              <SuiBox>
                <SuiTypography variant="caption" color="error" fontWeight="regular">
                  {formik.touched.email && formik.errors.email ? formik.errors.email : <></>}
                </SuiTypography>
              </SuiBox>
            </Grid>

            <Grid item xs={12}>
              <>
                <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                  <SuiTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Rôle
                  </SuiTypography>
                </SuiBox>

                <SuiSelect
                  options={availableRoles}
                  name="role"
                  onChange={(e) => handleSelectRole(e)}
                  value={selectedRole}
                  data-cy="selectRoleUserCreate"
                />
              </>
            </Grid>

            {role &&
              selectedRole &&
              selectedRole.value === IS_MANAGER_BRAND &&
              (role === IS_ADMIN || role === IS_MANAGER_BRAND) && (
                <Grid item xs={12}>
                  <>
                    <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Enseigne
                      </SuiTypography>
                    </SuiBox>

                    <Autocomplete
                      id="Enseigne"
                      options={filteredBrand || availableBrands}
                      getOptionLabel={(option) => option.label}
                      filterOptions={(x) => x}
                      isOptionEqualToValue={(option, value) =>
                        option ?? option.value === value.value
                      }
                      onInputChange={(e, value) => setSearchBrand(value)}
                      onChange={handleChangeBrand}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" placeholder="Enseigne" data-cy="selectBrandValue"/>
                      )}
                      data-cy="selectBrandUserCreate"
                    />
                  </>
                </Grid>
              )}
            {selectedRole &&
              (selectedRole.value === IS_MANAGER_SHOP ||
                selectedRole.value === IS_EMPLOYEE_SHOP) && (
                <Grid item xs={12}>
                  <>
                    <SuiBox ml={0.5} lineHeight={0} display="inline-block">
                      <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Point de vente
                      </SuiTypography>
                    </SuiBox>

                    <Autocomplete
                      multiple
                      id="shops"
                      options={availableShops}
                      getOptionLabel={(option) => option.label}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onChange={handleChangeShops}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" placeholder="Point de vente" />
                      )}
                    />
                  </>
                </Grid>
              )}
            <Grid container justifyContent="space-between" mt={4}>
              <Grid item xs={6} textAlign={"center"}>
                <SuiButton variant="gradient" color="error" onClick={handleCloseUserDrawer}>
                  Annuler
                </SuiButton>
              </Grid>
              <Grid item xs={6} textAlign={"center"}>
                <SuiButton
                  // disabled={!formik.dirty || !formik.isValid}F
                  variant="gradient"
                  color="info"
                  type="submit"
                  data-cy="BtnAddUserSubmit"
                >
                  Valider
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </SuiBox>
      </form>
    </UserCreateUpdateRoot>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(userCreateUpdate);
