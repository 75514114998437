import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const hubriseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HUBRISE_CONNECT_REQUEST:
      return { ...state, isLoading: true };
    case actionTypes.HUBRISE_CONNECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload  // Spread the entire payload which includes data and message
      };
    case actionTypes.HUBRISE_CONNECT_FAILURE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};

export default hubriseReducer;
