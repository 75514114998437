import { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import { getTickets } from "@sharecode/common/redux/actions/tickets";
import BlankPicture from "assets/images/no_picture.png";
import Table from "examples/Tables/Table";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { useSearchParams } from "react-router-dom";
import ActionCell from "../ActionCell";
import DefaultCell from "../DefaultCell";
import { Oval } from "@agney/react-loading";
import moment from "moment";
import axios from "axios";

const TableRequest = ({ tickets }) => {
  const [columns, setColumns] = useState();
  const [rows, setRows] = useState();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const cancelTokenSource = useRef();

  const imageStyle = { width: "50px", height: "50px", borderRadius: "6px" };
  
  useEffect(() => {
    setLoading(true);
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    cancelTokenSource.current = axios.CancelToken.source();

    new Promise(async (resolve, reject) => {
      const page = searchParams.get("page") || 1;
      const size = searchParams.get("size") || 10;
      const search = searchParams.get("search") || "";
      const startDate = searchParams.get("start_date") || "";
      const endDate = searchParams.get("end_date") || "";
      const area = searchParams.get("area") || "";
      const unassigned = searchParams.get("unassigned") || "";
      const paperTickets = searchParams.get("paper_tickets") || "";

      dispatch(
        getTickets(page, size, search, startDate, endDate, area, unassigned, paperTickets, cancelTokenSource)
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, [window.location.search]);

  useEffect(() => {
    if (tickets.results && tickets.results.length > 0) {
      setColumns([
        { name: "Photo", align: "center" },
        { name: "Utilisateur", align: "center" },
        { name: "Logo enseigne", align: "center" },
        { name: "Enseigne", align: "center" },
        { name: "Total", align: "center" },
        { name: "Note de frais", align: "center" },
        { name: "Ticket photo", align: "center" },
        { name: "Date de création", align: "center" },
        { name: "Action", align: "center" },
      ]);

      setRows(
        tickets.results.map((item) => ({
          Photo: (
            <img
              src={item.user.picture || BlankPicture}
              style={imageStyle}
              onError={(e) => (e.currentTarget.src = BlankPicture)}
            />
          ),
          Utilisateur: (
            <DefaultCell>
              {item.user.last_name && item.user.first_name
                ? `${item.user.first_name} ${item.user.last_name}`
                : "-"}
            </DefaultCell>
          ),
          "Logo enseigne": (
            <img
              src={item.brand.logo || BlankPicture}
              style={imageStyle}
              onError={(e) => (e.currentTarget.src = BlankPicture)}
            />
          ),
          Enseigne: <DefaultCell>{item.brand?.name || " "}</DefaultCell>,
          Total: <DefaultCell>{item.total || " "}</DefaultCell>,
          "Note de frais": <DefaultCell>{item.is_expense_report ? "✔️" : "❌"}</DefaultCell>,
          "Ticket photo": <DefaultCell>{item.is_paper_ticket ? "✔️" : "❌"}</DefaultCell>,
          "Date de création": (
            <DefaultCell>
              {item.created_at ? moment(item.created_at).format("DD-MM-YYYY") : " "}
            </DefaultCell>
          ),
          Action: <ActionCell id={item.id} fileUrl={item.file} />,
        }))
      );
    } else {
      setRows([]);
    }
  }, [tickets.results]);

  return (
    <>
      {loading ? (
        <SuiBox textAlign="center">
          <Oval width="50" color="info" />
        </SuiBox>
      ) : (
        columns && rows && columns.length > 0 && rows.length > 0 ? (
          <Table columns={columns} rows={rows} />
        ) : (
          <SuiBox textAlign="center" marginBottom={5}>
            <SuiTypography variant="h6" fontWeight="medium">
              Aucun ticket trouvé
            </SuiTypography>
          </SuiBox>
        )
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(TableRequest);