/* eslint-disable */

import * as types from './actionTypes';
import { BrandService } from '@sharecode/common/services/BrandService';

export const getBrand =
  (page, size, search, cancelToken) => (dispatch) => {
    return BrandService.getBrand(
      page,
      size,
      search,
      cancelToken
    ).then(
      (response) => {
        dispatch({
          type: types.GET_BRAND,
          payload: response,
        });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const getBrandCategories =
  (page, size, search, all, cancelToken) => (dispatch) => {
    return BrandService.getBrandCategories(
      page,
      size,
      search,
      all,
      cancelToken
    ).then(
      (response) => {
        dispatch({
          type: types.GET_BRAND_CATEGORIES,
          payload: response,
        });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const postBrandCategory = (payload) => (dispatch) => {
  return BrandService.postBrandCategory(payload)
    .then((response) => {
      dispatch({
        type: types.POST_BRAND_CATEGORY,
        payload: { newState: response?.data },
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error.message);
    });
};

export const updateBrandCategory = (payload) => (dispatch) => {
  return BrandService.updateBrandCategory(payload).then(
    (response) => {
      dispatch({
        type: types.UPDATE_BRAND_CATEGORY,
        payload: { newState: response.data },
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const deleteBrandCategory = (payload) => (dispatch) => {
  return BrandService.deleteBrandCategory(payload).then(
    (response) => {
      dispatch({
        type: types.DELETE_BRAND_CATEGORY,
        payload: { deleted: payload },
      });
      return Promise.resolve(payload);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getSingleBrand = (brandId) => (dispatch) => {
  return BrandService.getSingleBrand(brandId).then(
    (response) => {
      dispatch({
        type: types.GET_SINGLE_BRAND,
        payload: { brandId, response },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getBrandTickets =
  (page, size, search, startDate, endDate, area, brandId) =>
  (dispatch) => {
    return BrandService.getBrandTickets(
      page,
      size,
      search,
      startDate,
      endDate,
      area,
      brandId
    ).then(
      (response) => {
        dispatch({
          type: types.GET_BRAND_TICKETS,
          payload: response,
        });
        return Promise.resolve(response);
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      }
    );
  };

export const postBrand = (payload) => (dispatch) => {
  return BrandService.postBrand(payload).then(
    (response) => {
      dispatch({
        type: types.POST_BRAND,
        payload: { newState: response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const updateBrand = (payload) => (dispatch) => {
  return BrandService.updateBrand(payload).then(
    (response) => {
      dispatch({
        type: types.UPDATE_BRAND,
        payload: { newState: response.data },
      });
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const deleteBrand = (payload) => (dispatch) => {
  return BrandService.deleteBrand(payload).then(
    (response) => {
      dispatch({
        type: types.DELETE_BRAND,
        payload: { deleted: payload },
      });
      return Promise.resolve(payload);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getUsersByBrand = (id) => (dispatch) => {
  return BrandService.getUsersByBrand(id).then(
    (response) => {
      dispatch({
        type: types.GET_ADMIN_USERS_BY_BRAND,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getAdminUsersByBrand = (id) => (dispatch) => {
  return BrandService.getAdminUsersByBrand(id).then(
    (response) => {
      dispatch({
        type: types.GET_ADMIN_USERS_BY_BRAND,
        payload: response,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const clearBrandUsers = () => ({
  type: types.CLEAR_BRAND_USERS
});
