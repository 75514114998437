import { useEffect, useState, useCallback } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
// Soft UI Dashboard PRO React example components
import SimpleStatisticsCard from "examples/Cards/StatisticsCards/SimpleStatisticsCard";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import BarChart from "examples/Charts/BarCharts/VerticalBarChart";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import FilterButton from "examples/FilterButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// colors
import colors from "assets/theme/base/colors";
// assets

import { useRole } from "routes/ProtectedRoutes";
// Data fetching hooks
import useViewsChartData from "./data/viewsChartData";
import useClicksChartData from "./data/clicksChartData";
import useFavoritesChartData from "./data/favoritesChartData";
import useCpcChartData from "./data/cpcChartData";

// services
import { KpiService } from "@sharecode/common/services/KpiService";
// Custom components
import { DatePeriodFilter } from "components/Filters/DatePeriodFilter";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminCampaigns,
  fetchAdminCampaignStats,
  fetchAdminCampaignAds,
  fetchBrandsWithCampaign,
  fetchDailyAdminStatsByBrand,
  fetchWeeklyAdminStatsByBrand,
  fetchMonthlyAdminStatsByBrand,
} from "@sharecode/common/redux/actions/campaignsActions";
import { getSingleBrand } from "@sharecode/common/redux/actions/brand";
import { updateToken } from "@sharecode/common/redux/actions/authActions";

function DashboardRetailerAdmin() {
  const dispatch = useDispatch();

  const [formattedToken, setFormattedToken] = useState("");
  // admin campaigns
  const campaigns = useSelector((state) => state.campaignReducer.campaigns);
  // admin campaigns ads
  const allCampaignAds = useSelector((state) => state.campaignReducer.campaignAdsById);
  // brands with campaigns
  const brands = useSelector((state) => state.campaignReducer.brands.data);
  const brandsCampaignIds = [];
  brands?.forEach((brand) => {
    if (brand) {
      brandsCampaignIds.push(brand.AdsBrandsID);
    }
  });

  // brands data
  const brandsData = useSelector((state) => state.brand);
  const brandsDataArray = Object.values(brandsData);
  // access the token to update it on UAT
  const token = useSelector((state) => state?.campaignReducer?.token);

  // single campaign selected to view details
  const selectedCampaignId = useSelector((state) => state.campaignReducer.selectedCampaignId);
  // single campaign ads
  const selectedCampaignAds = useSelector((state) => state.campaignReducer.campaignAds.data);

  // Function to calculate spent amount for a single campaign
  function calculateSpentAmount(campaign) {
    return (campaign.clickBid / 100) * campaign.clickCurrent;
  }
  // Function to calculate total number of clicks for a single campaign
  function calculateTotalClicks(campaign) {
    return campaign.clickCurrent;
  }

  // Calculate totals using failsafe defaults
  const totalSpentAmount = (campaigns?.data || []).reduce((total, campaign) => {
    if (!campaign) return total;
    return total + calculateSpentAmount(campaign);
  }, 0);
  const totalClicks = (campaigns?.data || []).reduce((total, campaign) => {
    if (!campaign) return total;
    return total + calculateTotalClicks(campaign);
  }, 0);

  // Update formattedToken whenever token changes
  useEffect(() => {
    const newFormattedToken = token?.split(" ")[1];
    if (newFormattedToken) {
      setFormattedToken(newFormattedToken);
    }
  }, [token]);

  const userId = useSelector((state) => state?.auth.user);
  // update token on UAT
  useEffect(() => {
    if (userId && formattedToken && !window.location.hostname.includes("dash.app-check.fr")) {
      dispatch(updateToken(userId, formattedToken));
    }
  }, [formattedToken, userId, dispatch]);

  const [loading, setLoading] = useState(false);
  const { gradients } = colors;
  const [listKpi, setListKpi] = useState([]);
  const role = useRole();

  // date filters states
  const periodicityOptions = [
    { value: "daily", label: "Jour" },
    { value: "weekly", label: "Semaine" },
    { value: "monthly", label: "Mois" },
  ];
  const [periodicity, setPeriodicity] = useState(periodicityOptions[0].value);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Initialize selected campaigns and stories IDs
  const [selectedCampaignIds, setSelectedCampaignIds] = useState(
    selectedCampaignId ? [selectedCampaignId] : []
  );
  const [selectedStoryIds, setSelectedStoryIds] = useState(
    (selectedCampaignAds || []).map((ad) => ad?.id).filter(Boolean)
  );

  const [selectedStory, setSelectedStory] = useState(null);
  // Initialize selectedCampaign as null and update when campaigns data becomes available
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  useEffect(() => {
    if (selectedCampaignId && (campaigns?.data || []).length > 0) {
      const foundCampaign = (campaigns.data || []).find(
        (campaign) => campaign && campaign.id === selectedCampaignId
      );
      setSelectedCampaign(foundCampaign || null);
    }
  }, [selectedCampaignId, campaigns.data]);

  const [selectedBrandIds, setSelectedBrandIds] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [mode, setMode] = useState(selectedCampaignId ? "single" : "all");

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  // fetching admin campaigns
  useEffect(() => {
    setLoading(true);
    dispatch(fetchAdminCampaigns())
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
        setLoading(false);
      });
  }, [dispatch]);

  // fetching admin campaigns stats
  useEffect(() => {
    if ((campaigns?.data || []).length > 0) {
      setLoading(true);
      Promise.all(
        (campaigns.data || [])
          .filter((campaign) => campaign)
          .map((campaign) => dispatch(fetchAdminCampaignStats(campaign.id)))
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching campaign stats:", error);
          setLoading(false);
        });
    }
  }, [campaigns.data, dispatch]);

  // fetching admin campaigns ads
  useEffect(() => {
    if ((campaigns?.data || []).length > 0) {
      setLoading(true);
      Promise.all(
        (campaigns.data || [])
          .filter((campaign) => campaign)
          .map((campaign) => dispatch(fetchAdminCampaignAds(campaign.id)))
      )
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching campaign ads:", error);
          setLoading(false);
        });
    }
  }, [campaigns.data, dispatch]);

  // fetching brands ids with campaigns
  useEffect(() => {
    setLoading(true);
    dispatch(fetchBrandsWithCampaign())
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching brands with campaigns:", error);
        setLoading(false);
      });
  }, [dispatch]);

  // fetching brand data by brandId
  useEffect(() => {
    if (brandsCampaignIds.length > 0) {
      setLoading(true);
      Promise.all(brandsCampaignIds.map((brandId) => dispatch(getSingleBrand(brandId))))
        .then(() => setLoading(false))
        .catch((error) => {
          console.error("Error fetching brands:", error);
          setLoading(false);
        });
    }
  }, [brands, dispatch]);

  // fetching daily stats by brand
  useEffect(() => {
    if (brandsCampaignIds.length > 0) {
      setLoading(true);
      Promise.all(
        brandsCampaignIds.map((brandId) => dispatch(fetchDailyAdminStatsByBrand(brandId)))
      )
        .then(() => setLoading(false))
        .catch((error) => {
          console.error("Error fetching daily stats by brand:", error);
          setLoading(false);
        });
    }
  }, [brands, dispatch]);

  // fetching weekly stats by brand
  useEffect(() => {
    if (brandsCampaignIds.length > 0) {
      setLoading(true);
      Promise.all(
        brandsCampaignIds.map((brandId) => dispatch(fetchWeeklyAdminStatsByBrand(brandId)))
      )
        .then(() => setLoading(false))
        .catch((error) => {
          console.error("Error fetching weekly stats by brand:", error);
          setLoading(false);
        });
    }
  }, [brands, dispatch]);

  // fetching monthly stats by brand
  useEffect(() => {
    if (brandsCampaignIds.length > 0) {
      setLoading(true);
      Promise.all(
        brandsCampaignIds.map((brandId) => dispatch(fetchMonthlyAdminStatsByBrand(brandId)))
      )
        .then(() => setLoading(false))
        .catch((error) => {
          console.error("Error fetching monthly stats by brand:", error);
          setLoading(false);
        });
    }
  }, [brands, dispatch]);

  // Fetch KPI data
  useEffect(() => {
    if (isRoleDefined()) {
      KpiService.getKpiDashboard()
        .then((r) => setListKpi(r))
        .catch((error) => {
          console.error("Error fetching KPI Data:", error);
        });
    }
  }, []);

  // Set start and end date for the current week
  useEffect(() => {
    const currentDate = new Date();
    const firstDay = currentDate.getDate() - currentDate.getDay() + 1;
    const lastDay = firstDay + 6;
    const firstDayDate = new Date(currentDate.setDate(firstDay))
      .toISOString()
      .split("T")[0];
    const lastDayDate = new Date(currentDate.setDate(lastDay))
      .toISOString()
      .split("T")[0];
    setStartDate(firstDayDate);
    setEndDate(lastDayDate);
  }, []);

  // display all brands by default, or filter by selected brand
  const brandOptions = brandsCampaignIds.map((brandId) => ({
    value: brandId,
    label:
      brandsDataArray.find((brand) => brand && brand.id === brandId)?.name || "",
  }));

  // Convert campaign and story data to options for the filter button
  const campaignOptions = (campaigns?.data || [])
    .filter(
      (campaign) =>
        campaign &&
        (selectedBrandIds.length === 0 ||
          selectedBrandIds.includes(campaign.brandsBrandID))
    )
    .map((campaign) => ({
      value: campaign.id,
      label: campaign.campaignName,
    }));

  const relevantCampaignIdsForSelectedBrands = (campaigns?.data || [])
    .filter(
      (campaign) =>
        campaign &&
        (selectedBrandIds.length === 0 ||
          selectedBrandIds.includes(campaign.brandsBrandID))
    )
    .map((campaign) => campaign.id);

  const storyOptions = Object.entries(allCampaignAds || {})
    .filter(
      ([campaignId, campaign]) =>
        relevantCampaignIdsForSelectedBrands.includes(parseInt(campaignId)) &&
        (selectedCampaignIds.length === 0 ||
          selectedCampaignIds.includes(parseInt(campaignId)))
    )
    .flatMap(([_, campaign]) => (campaign.data || []))
    .map((ad) => ({
      value: ad.id,
      label: `${ad.ads_name} (${ad.ads_campaign_order_nb})`,
    }));

  // handle campaign & story selection
  const handleCampaignSelect = useCallback((selectedOption) => {
    setSelectedCampaignIds(selectedOption ? [selectedOption.value] : []);
    setSelectedCampaign(selectedOption);
  }, []);

  useEffect(() => {
    // Only call if mode is "single" and a valid selectedCampaign exists
    if (mode === "single" && selectedCampaign && (campaigns?.data || []).length > 0) {
      handleCampaignSelect({
        value: selectedCampaign.id,
        label: selectedCampaign.campaignName,
      });
    }
  }, [mode, campaigns.data, selectedCampaign, handleCampaignSelect]);

  const handleStorySelect = useCallback((selectedOption) => {
    setSelectedStoryIds(selectedOption ? [selectedOption.value] : []);
    setSelectedStory(selectedOption);
  }, []);

  const handleBrandSelect = useCallback((selectedOption) => {
    setSelectedBrandIds(selectedOption ? [selectedOption.value] : []);
    setSelectedBrand(selectedOption);
  }, []);

  const handleClearPeriodFilter = useCallback(() => {
    setStartDate("");
    setEndDate("");
  }, []);

  const handleClearCampaignAndStoryFilter = useCallback(() => {
    setSelectedCampaignIds([]);
    setSelectedStoryIds([]);
    setSelectedBrandIds([]);
  }, []);

  const handlePeriodicityChange = useCallback((option) => {
    const isOptionDisabled = periodicityOptions.find(
      (o) => o.value === option.value && o.disabled
    );
    if (!isOptionDisabled) {
      setPeriodicity(option.value);
    } else {
      showSnackbar("Option disponible prochainement.");
    }
  }, []);

  const clicksChartData = useClicksChartData(
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    selectedBrandIds,
    startDate,
    endDate
  );

  const viewsChartData = useViewsChartData(
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    selectedBrandIds,
    startDate,
    endDate
  );

  const favoritesChartData = useFavoritesChartData(
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    selectedBrandIds,
    startDate,
    endDate
  );

  const cpcChartData = useCpcChartData(
    periodicity,
    selectedCampaignIds,
    selectedStoryIds,
    selectedBrandIds,
    startDate,
    endDate
  );

  const isRoleDefined = () => {
    return role && role !== "null" && role !== "undefined";
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={0} mt={6}>
        <SuiBox py={0}>
          {/* Section des SimpleStatisticsCard */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Campagnes Actives", fontWeight: "medium" }}
                count={(campaigns?.data || []).filter(
                  (campaign) => campaign && !campaign.has_ended
                ).length}
                icon={{ component: "campaign_icon" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Montant dépensé", fontWeight: "medium" }}
                count={(totalSpentAmount || 0).toFixed(2) + "€"}
                icon={{ component: "euro_symbol" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="info"
                title={{ text: "Nb de clicks", fontWeight: "medium" }}
                count={totalClicks}
                icon={{ component: "touch_app" }}
                direction="left"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <SimpleStatisticsCard
                bgColor="gradient"
                title={{ text: "Ventes générées", fontWeight: "small" }}
                count="Prochainement"
                icon={{ color: "info", component: "construction" }}
                direction="left"
              />
            </Grid>
          </Grid>
          <SuiBox mt={6}>
            <Divider />
          </SuiBox>
          {/* Section de la présentation des filtres */}
          <SuiBox mt={4}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="div"
                  align="center"
                  style={{ color: "#344767" }}
                >
                  Sélectionnez la période d’analyse
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h5"
                  component="div"
                  align="center"
                  style={{ color: "#344767" }}
                >
                  Sélectionnez la campagne à analyser
                </Typography>
              </Grid>
            </Grid>
          </SuiBox>
          {/* Section des filtres */}
          <SuiBox mt={4} mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePeriodFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  handleClearPeriodFilter={handleClearPeriodFilter}
                  hideFilterButton={true}
                />
                <SuiBox mt={2} sx={{ width: 180 }}>
                  <SuiSelect
                    placeholder="Trier par"
                    options={periodicityOptions}
                    value={periodicityOptions.find(
                      (option) => option.value === periodicity
                    )}
                    onChange={handlePeriodicityChange}
                  />
                </SuiBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FilterButton
                  onCampaignSelect={handleCampaignSelect}
                  onStorySelect={handleStorySelect}
                  onBrandSelect={handleBrandSelect}
                  campaignOptions={campaignOptions}
                  storyOptions={storyOptions}
                  brandOptions={brandOptions}
                  handleClearCampaignAndStoryFilter={handleClearCampaignAndStoryFilter}
                  selectedCampaignIds={selectedCampaignIds}
                  selectedStoryIds={selectedStoryIds}
                  selectedBrandIds={selectedBrandIds}
                  selectedStory={selectedStory}
                  selectedCampaign={selectedCampaign}
                  selectedBrand={selectedBrand}
                  mode={mode}
                />
              </Grid>
            </Grid>
          </SuiBox>
          {/* Charts data */}
          <SuiBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <GradientLineChart title="Nombre de vues" chart={viewsChartData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <BarChart title="Nombre de Clics" chart={clicksChartData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ProgressLineChart
                  title="Ajouts en Favoris"
                  color="primary"
                  chart={{ labels: favoritesChartData.labels, data: favoritesChartData.data }}
                  icon="favorite"
                  count={favoritesChartData.totalCount}
                  height="19.125rem"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <BarChart title="Coût par clic" chart={cpcChartData} />
              </Grid>
              <Grid item xs={12} lg={6}>
                {/* Additional charts can be added here */}
              </Grid>
            </Grid>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </DashboardLayout>
  );
}

export default DashboardRetailerAdmin;
