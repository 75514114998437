const initialState = {
  results: [],
  brandUsers: null,
  count: 0,
  next: null,
  previous: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case 'GET_BRAND':
      return {
        ...state,
        ...payload,
        brandUsers: state.brandUsers,
      };
    case 'GET_BRAND_CATEGORIES':
      return {
        ...state,
        ...payload,
        brandUsers: state.brandUsers,
      };
    case 'GET_BRAND_TICKETS':
      return {
        ...state,
        ...payload,
        brandUsers: state.brandUsers,
      };
    case 'GET_SINGLE_BRAND':
      const { brandId, response } = payload;
      return {
        ...state,
        [brandId]: response,
      };
    case 'POST_BRAND':
      return state;
    case 'POST_BRAND_CATEGORY':
      return state;
    case 'UPDATE_BRAND':
      return state;
    case 'DELETE_BRAND':
      return state;
    case 'DELETE_BRAND_CATEGORY':
      return state;
    case 'GET_USERS_BY_BRAND':
      return payload || state;
    case 'GET_ADMIN_USERS_BY_BRAND':
      return {
        ...state,
        brandUsers: payload,
      };
    case 'CLEAR_BRAND_USERS':
      return {
        ...state,
        brandUsers: null,
      };
    default:
      return state;
  }
}
