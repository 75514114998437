// React and libs
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

// Material UI
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// SUI
import SuiBox from "components/SuiBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";


// Soft UI Dashboard PRO React context
import { useSoftUIController, setOpenPartnerDrawer } from "context";

// data
import { IS_ADMIN } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_BRAND } from "@sharecode/common/redux/actions/actionTypes";
import { IS_MANAGER_SHOP } from "@sharecode/common/redux/actions/actionTypes";
import { useRole } from "routes/ProtectedRoutes";

// Components
import TableRequest from "./components/TableRequest";
import PageListing from "components/PagesListing";
import { SizeSelectorFilter } from "components/Filters/SizeSelectorFilter";
import PartnerCreateUpdate from "examples/Drawers/partnerCreateUpdate";


function ManagePosPartners(props) {
  const [controller, dispatch] = useSoftUIController();
  const { openPartnerDrawer } = controller;
  const handlePartnerDrawerOpen = () => {
    setOpenPartnerDrawer(dispatch, !openPartnerDrawer);
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const [sizeSelected, setSizeSelected] = useState();
  const [searchValue, setSearchValue] = useState("");

  const partnertoEdit = useSelector((state) => state?.posReducer?.partnerToEdit);

  const role = useRole();

  const handleChangeSize = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", e.value);
    searchParams.set("search", "");
    setSizeSelected({ value: e.value, label: e.label });
    setSearchParams(searchParams);
  };


  useEffect(() => {
    const search = searchParams.get("search") || "";
    const size = searchParams.get("size")
      ? { value: searchParams.get("size"), label: searchParams.get("size") }
      : { value: "10", label: "10" };

    setSearchValue(search);
    setSizeSelected(size);
  }, [searchParams]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <SuiBox py={2}>
          <Card>
            <SuiBox
              p={2}
              display="flex"
              alignItems="baseline"
              justifyContent="space-between"
              gap={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <SuiTypography variant="h6" data-cy="TitleListPartner">Liste des partenaires</SuiTypography>
              <SuiBox
                display="flex"
                gap={2}
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                }}
              >
                {role && [IS_ADMIN, IS_MANAGER_BRAND, IS_MANAGER_SHOP].indexOf(role) > -1 && (
                  <SuiButton variant="gradient" color="info" onClick={handlePartnerDrawerOpen} data-cy="BtnAddPartner">
                    <Icon>add</Icon> &nbsp; Ajouter un partenaire
                  </SuiButton>
                )}
              </SuiBox>
            </SuiBox>
            <SuiBox
              p={2}
              display="flex"
              justifyContent="space-between"
              gap={4}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <SizeSelectorFilter
                sizeValue={sizeSelected}
                handleSizeChange={handleChangeSize}
                totalCount={props?.posReducer?.posPartners?.data?.count}
              />
            </SuiBox>
            <TableRequest />
          </Card>
          <PageListing
            data={{
              count: props?.posReducer?.posPartners?.data?.count || 0,
              next: props?.posReducer?.posPartners?.data?.next,
              previous: props?.posReducer?.posPartners?.data?.previous,
              currentPage: parseInt(searchParams.get("page") || "1"),
              pageSize: parseInt(searchParams.get("size") || "10"),
              onPageChange: (newPage) => {
                searchParams.set("page", newPage);
                setSearchParams(searchParams);
              }
            }}
          />
        </SuiBox>
      </DashboardLayout>
      <PartnerCreateUpdate partnerToEdit={partnertoEdit} />
    </>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ManagePosPartners);
