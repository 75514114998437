import * as actionTypes from './actionTypes';
import HubriseService from '../../services/hubriseService';

export const hubriseConnect = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.HUBRISE_CONNECT_REQUEST });
    try {
      const response = await HubriseService.connect();
      dispatch({ type: actionTypes.HUBRISE_CONNECT_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: actionTypes.HUBRISE_CONNECT_FAILURE, payload: error });
    }
  };
};

export const hubriseDisconnect = (disconnectUrl) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.HUBRISE_DISCONNECT_REQUEST });
    try {
      await HubriseService.disconnect(disconnectUrl);
      dispatch({ type: actionTypes.HUBRISE_DISCONNECT_SUCCESS });
      // Refresh connection status after disconnect
      dispatch(hubriseConnect());
    } catch (error) {
      dispatch({ type: actionTypes.HUBRISE_DISCONNECT_FAILURE, payload: error });
    }
  };
};