import axios from 'axios';
import { URL_HUBRISE_CONNECT, getAuthorizationToken } from './constantApi';

const HubriseService = {
  connect: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(URL_HUBRISE_CONNECT, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
    });
  },

  disconnect: (url) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
    });
  },
};

export default HubriseService;
