export const DASHBOARD = '/dashboard';
export const CLIENTS_EXPERIENCES = '/experiences-clients';
export const SHOP_LIST = '/shop-list';
export const CLIENTS_LIST = '/listes-clients';
export const CHAT = '/chat';
export const CREATE_OFFER = '/create-offer';
export const BRAND_LIST = '/brand-list';
export const TICKET_LIST = '/ticket-list';
export const USER_TICKET_LIST = '/user/:uid/tickets';
export const BRAND_TICKET_LIST = '/brands/:bid/tickets';
export const REVIEWS_LIST = '/reviews-list';
export const SECTOR_LIST = '/sector-list';
export const MANAGE_USERS = '/manage-users';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const SIGN_UP_ACTIVATION = '/sign-up/activation/:key';
export const NEW_PASSWORD = '/new-password/:uid/:key';
export const RESET_PASSWORD = '/reset-password';
export const PROFILE = '/profile';
export const NOT_FOUND = '/not-found';
export const WAIT_PAGE_SOCIAL_GOOGLE =
  '/accounts/login/google/callback/';
export const WAIT_PAGE_SOCIAL_LINKEDIN =
  '/accounts/login/linkedin/callback/';
export const MANAGE_CAMPAIGNS = '/manage-campaigns';
export const MANAGE_CAMPAIGNS_ADMIN = '/manage-campaigns-admin';
export const FACTURATION = '/facturation';
export const CREATE_STORY = '/create-story';
export const DASHBOARD_RETAILER_ADMIN = '/dashboard-retailer-admin';
export const CREATE_OFFER_ADMIN = '/create-offer-admin';
export const MANAGE_POS_PARTNERS = '/manage-pos-partners';
export const MANAGE_POS_BUSINESS = '/manage-pos-partners/:id/business';
export const HUBRISE_INTEGRATION = '/integration';
