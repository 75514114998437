import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

import { hubriseConnect, hubriseDisconnect } from '@sharecode/common/redux/actions/hubrise';

const HubriseIntegration = () => {
  const dispatch = useDispatch();
  const { data, message, isLoading } = useSelector((state) => state.hubriseReducer);

  useEffect(() => {
    dispatch(hubriseConnect());
  }, [dispatch]);

  const handleConnectClick = () => {
    if (data?.connect_url) {
      window.open(data.connect_url, '_blank', 'noopener,noreferrer');
    }
  };

  const handleDisconnectClick = () => {
    if (data?.disconnect_url) {
      dispatch(hubriseDisconnect(data.disconnect_url));
    }
  };

  const handleGoToHubrise = () => {
    if (data?.GoToLink) {
      window.open(data.GoToLink, '_blank', 'noopener,noreferrer');
    }
  };

  const isConnected = message === "connected";
  const hubriseMeta = data?.data;

  return (
    <DashboardLayout>
      <DashboardNavbar absolute={false} light={false} false isMini={false} />
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh'
      }}>
        <Card sx={{ maxWidth: 400, textAlign: 'center', p: 2 }}>
          <CardContent>
            <Typography variant="h4" component="div" gutterBottom>
              HubRise
            </Typography>
            {!isConnected && (
              <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 3 }}>
                Share data with other applications
              </Typography>
            )}

            {isLoading ? (
              <CircularProgress />
            ) : isConnected ? (
              <Stack spacing={3}>
                <Box>
                  <Typography variant="h6" color="success.main" gutterBottom>
                    Connected to HubRise
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Stack spacing={2} sx={{ textAlign: 'left', px: 2 }}>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Location
                      </Typography>
                      <Typography variant="body1">
                        {`${hubriseMeta?.account_name} ${hubriseMeta?.location_name} - ${hubriseMeta?.location_id}`}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Catalog
                      </Typography>
                      <Typography variant="body1">
                        {`${hubriseMeta?.catalog_name} - ${hubriseMeta?.catalog_id}`}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" color="text.secondary">
                        Customer List
                      </Typography>
                      <Typography variant="body1">
                        {`${hubriseMeta?.customer_list_name} - ${hubriseMeta?.customer_list_id}`}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: 2,
                  alignItems: 'center'
                }}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleGoToHubrise}
                    sx={{ textDecoration: 'none' }}
                  >
                    Go to HubRise
                  </Link>
                  <Divider orientation="vertical" flexItem />
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleDisconnectClick}
                    color="error"
                    sx={{ textDecoration: 'none' }}
                  >
                    Disconnect
                  </Link>
                </Box>
              </Stack>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleConnectClick}
                disabled={!data?.connect_url}
              >
                Connect to HubRise
              </Button>
            )}
          </CardContent>
        </Card>
      </Box>
    </DashboardLayout>
  );
};

export default HubriseIntegration;